import "./ContactForm.scss";

import React from "react";
import { withPrefix } from "gatsby";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import axios from "axios";
import { Link as AnchorLink } from "react-scroll";

const FORM_ENDPOINT = "";

export default function ReservaGruposForm({ restaurantData }) {
	const { t } = useTranslation();
	const [loading, setLoading] = React.useState(false);
	const [toSend, setToSend] = React.useState({
		from_email: "",
		name: "",
		phone: "",
		date: "",
		restaurant: "",
		hour: "",
		adults: 8,
		kids: 0,
		comment: "",
		section: "ReservaGrupos",
		data_protection: "No",
		com_comercial: "No",
	});
	// console.log(toSend);

	const [formSent, setFormSent] = React.useState(false);
	const [formError, setFormError] = React.useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();

		setFormError(false);
		setLoading(true);

		axios({
			method: "post",
			// url: "https://peim.es/php_test/begin/envioReservasGrupo.php", // for testing purposes
			url: withPrefix("/php/envioReservasGrupo.php"),

			data: {
				...toSend,
			},
		})
			.then((res) => {
				setLoading(false);

				setFormSent(true);
				// console.log(res);
			})
			.catch((err) => {
				setLoading(false);
				setFormError(true);
				console.log(err);
			});
	};

	const handleChange = (e) => {
		if (e.target.type === "checkbox") {
			return setToSend((prevState) => {
				return {
					...prevState,
					[e.target.name]: e.target.checked
						? "Si. Fecha: " +
						  new Date() +
						  ". Texto: " +
						  e.target?.labels[0]?.innerText
						: "No",
				};
			});
		}

		if (e.target.type === "date") {
			const date = new Date(e.target.value);
			if (!isNaN(date)) {
				return setToSend((prevState) => {
					return {
						...prevState,
						[e.target.name]:
							date.getDate() +
							"/" +
							(date.getMonth() + 1) +
							"/" +
							date.getFullYear(),
					};
				});
			}
		}

		if (e.target.name === "adults" || e.target.name === "kids") {
			const minimum = e.target.name === "adults" ? 8 : 0;
			e.target.value > 99
				? (e.target.value = 99)
				: e.target.value < minimum
				? (e.target.value = minimum)
				: (e.target.value = e.target.value); // eslint-disable-line
		}

		return setToSend((prevState) => {
			return { ...prevState, [e.target.name]: e.target.value };
		});
	};

	const onPeopleChange = (field, input) => {
		const minimum = field === "adults" ? 8 : 0;
		var inputPeople = parseInt(input);
		if (inputPeople > 99) {
			inputPeople = 99;
		} else if (inputPeople < minimum) {
			inputPeople = minimum;
		}

		return setToSend((prevState) => {
			return { ...prevState, [field]: inputPeople };
		});
	};

	const anchorLinkProps = {
		smooth: true,
		duration: 750,
		offset: -100,
		delay: 0,
	};

	return (
		<div>
			<SwitchTransition mode="out-in">
				<CSSTransition key={formSent} timeout={400} classNames="fade">
					<>
						{!formSent ? (
							<div>
								<form
									action={FORM_ENDPOINT}
									onSubmit={handleSubmit}
									onChange={handleChange}
									method="POST"
									target="_blank"
									className="contactForm"
									id="reservaGruposForm"
								>
									<h2 className="lowercase small">
										<Trans>
											Puedes ver más detalles del proceso y los menús
											disponibles {"  "}
											<AnchorLink to="detalles" {...anchorLinkProps}>
												más abajo
											</AnchorLink>
										</Trans>
									</h2>
									<div className="inputContainer">
										<div className="leftNum h2">01/</div>{" "}
										<input
											type="text"
											placeholder={t("Nombre y apellidos")}
											id="name"
											name="name"
											title=" "
											required
										/>
									</div>

									<div className="inputContainer">
										<div className="leftNum h2">02/</div>{" "}
										<input
											type="email"
											placeholder={t("Email")}
											id="from_email"
											name="from_email"
											title=" "
											required
										/>
									</div>

									<div className="inputContainer">
										<div className="leftNum h2">03/</div>{" "}
										<input
											type="tel"
											placeholder={t("Teléfono de contacto")}
											id="phone"
											name="phone"
											title=" "
											required
										/>
									</div>

									<div className="inputContainer">
										<div className="leftNum h2">04/</div>{" "}
										<input
											type="date"
											placeholder={t("Fecha")}
											id="date"
											name="date"
											title=" "
											required
											className={`${toSend.date ? "filled" : ""}`}
										/>
									</div>

									<div className="inputContainer">
										<div className="leftNum h2">05/</div>{" "}
										<div className="selectContainer">
											<select
												id="frestaurant"
												name="restaurant"
												placeholder={t("Restaurante")}
												required
												className="p1 formel"
												defaultValue={toSend.restaurant || ""}
											>
												<option value="" disabled>
													{t("Restaurante")}
												</option>
												{restaurantData.nodes.map((restaurant) => {
													if (restaurant.frontmatter.coverManagerId) {
														return (
															<option
																value={restaurant.frontmatter.id}
																key={restaurant.frontmatter.id}
																defaultValue={
																	toSend.restaurant === restaurant.frontmatter
																}
															>
																Begin {restaurant.frontmatter.simpleName}
															</option>
														);
													}
													return null;
												})}
											</select>
										</div>
									</div>

									<div className="inputContainer">
										<div className="leftNum h2">06/</div>{" "}
										<div className="selectContainer">
											<select
												id="hour"
												name="hour"
												placeholder={t("Turno")}
												required
												className="p1 formel"
												defaultValue={toSend.hour || ""}
											>
												<option value="" disabled>
													{t("Turno")}
												</option>
												<option value="Brunch">{t("Brunch")}</option>
												<option value="Comida">{t("Comida")}</option>
												<option value="Cena">{t("Cena")}</option>
											</select>
										</div>
									</div>

									<div className="inputContainer">
										<div className="leftNum h2">07/</div>{" "}
										<p className="question">
											<Trans>
												¿Cuántas personas seréis? (mínimo 11 personas en total)
											</Trans>
										</p>
										<div className="half">
											<div className="counterInput">
												<label
													htmlFor="adults"
													id="adults"
													name="adults"
													placeholder={t("Adultos")}
													required
													aria-label={t("Adultos")}
												>
													<p>
														<Trans>Adultos</Trans>
													</p>
												</label>
												<div>
													<button
														onClick={(e) => {
															e.preventDefault();
															onPeopleChange("adults", toSend.adults - 1);
														}}
														className={`${
															toSend.adults === 8 ? "disabled" : ""
														}`}
													>
														-
													</button>
													<input
														type="text"
														value={toSend.adults}
														id="adults"
														name="adults"
														title=""
														onChange={handleChange}
													/>
													<button
														onClick={(e) => {
															e.preventDefault();
															onPeopleChange("adults", toSend.adults + 1);
														}}
														className={`${
															toSend.adults === 99 ? "disabled" : ""
														}`}
													>
														+
													</button>
												</div>
											</div>
											<div className="counterInput">
												<label
													htmlFor="kids"
													id="kids"
													name="kids"
													placeholder={t("Niños (hasta 12 años)")}
													required
													aria-label={t("Niños (hasta 12 años)")}
												>
													<p>
														<Trans>Niños (hasta 12 años)</Trans>
													</p>
												</label>
												<div>
													<button
														onClick={(e) => {
															e.preventDefault();
															onPeopleChange("kids", toSend.kids - 1);
														}}
														className={`${toSend.kids === 0 ? "disabled" : ""}`}
													>
														-
													</button>
													<input
														type="text"
														value={toSend.kids}
														id="kids"
														name="kids"
														title=""
														onChange={handleChange}
													/>
													<button
														onClick={(e) => {
															e.preventDefault();
															onPeopleChange("kids", toSend.kids + 1);
														}}
														className={`${
															toSend.kids === 99 ? "disabled" : ""
														}`}
													>
														+
													</button>
												</div>
											</div>
										</div>
									</div>

									<div className="inputContainer">
										<div className="leftNum h2">08/</div>{" "}
										<TextareaAutosize
											placeholder={t("Mensaje")}
											id="comment"
											name="comment"
											title=" "
											value={toSend.comment}
											maxRows={4}
											minRows={1}
										/>
									</div>

									<p className="note">
										<Trans>
											*Completa todos los campos para poder continuar
										</Trans>
									</p>

									<div className="dataProtection">
										<div className="dataCheck">
											<input
												type="checkbox"
												name="data_protection"
												id="data_protection"
												required
											/>
											<label
												htmlFor="data_protection"
												aria-label={t(
													"Consiento el uso de mis datos personales para que atiendan mi solicitud"
												)}
											>
												<p>
													<Trans ns="dataProtection">
														Consiento el uso de mis datos personales para que
														atiendan mi solicitud, según lo establecido en su{" "}
														<a
															href="/legal/politica-privacidad"
															target="_blank"
															rel="noopener noreferrer"
														>
															<Trans ns="dataProtection">
																Política de Privacidad
															</Trans>
														</a>{" "}
														y declaro tener cumplidos los catorce (14) años de
														edad, asumiendo las posibles responsabilidades
														legales sobre la veracidad de esta declaración.
													</Trans>
												</p>
											</label>
										</div>

										<div className="dataCheck">
											<input
												type="checkbox"
												name="com_comercial"
												id="com_comercial"
											/>
											<label
												htmlFor="com_comercial"
												aria-label={t(
													"Consiento la recepción de comunicaciones del restaurante por e-mail y/o SMS con fines comerciales"
												)}
											>
												<p>
													<Trans ns="dataProtection">
														Consiento la recepción de comunicaciones del
														restaurante por e-mail y/o SMS con fines comerciales
													</Trans>
												</p>
											</label>
										</div>
									</div>

									<div className="formSubmit">
										<button
											type="submit"
											aria-label={t("Enviar")}
											className="roundButton"
										>
											{loading && !formError ? (
												<Trans>Enviando...</Trans>
											) : (
												<Trans>Enviar</Trans>
											)}
										</button>
										{formError && (
											<div>
												<p>
													<Trans>
														¡Ups! Ha habido un error en el envío del mensaje.
														¡Lo sentimos!
													</Trans>
												</p>
												<p>
													<Trans>
														Vuelve a intentarlo en unos momentos. Si el problema
														persiste, también puedes contactarnos a través del
														chat o de nuestras Redes Sociales:
													</Trans>
												</p>
											</div>
										)}
									</div>

									{/* <div className="dataProtection">
										<p>
											<Trans ns="dataProtection">
												Información básica sobre protección de datos
											</Trans>
											:
										</p>
										<table>
											<tbody>
												<tr>
													<td>
														<Trans ns="dataProtection">Responsable</Trans>:
													</td>
													<td>BEGIN RESTAURANTES, S.L.</td>
												</tr>
												<tr>
													<td>
														<Trans ns="dataProtection">Domicilio</Trans>:
													</td>
													<td>
														Av. Cortes Valencianas, 50, CP 46015 Valencia
														(Valencia)
													</td>
												</tr>
												<tr>
													<td>
														<Trans ns="dataProtection">Finalidad</Trans>:
													</td>
													<td>
														<Trans ns="dataProtection">
															Atender su solicitud de información
														</Trans>
														.
													</td>
												</tr>
												<tr>
													<td>
														<Trans ns="dataProtection">Legitimacion</Trans>
													</td>
													<td>
														<Trans ns="dataProtection">
															Sus datos serán tratados solo con su
															consentimiento, al marcar la casilla mostrada en
															este formulario
														</Trans>
														.
													</td>
												</tr>
												<tr>
													<td>
														<Trans ns="dataProtection">Destinatarios</Trans>:
													</td>
													<td>
														<Trans ns="dataProtection">
															Sus datos no serán cedidos a terceros
														</Trans>
														.
													</td>
												</tr>
												<tr>
													<td>
														<Trans ns="dataProtection">Derechos</Trans>:
													</td>
													<td>
														<Trans ns="dataProtection">
															Tiene derecho a solicitarnos acceder a sus datos,
															corregirlos o eliminarlos, también puede
															solicitarnos limitar su tratamiento, oponerse a
															ello y a la portabilidad de sus datos,
															dirigiéndose a nuestra dirección postal o a
														</Trans>{" "}
														<a href="mailto:contacto@beginrestaurante.com">
															contacto@beginrestaurante.com
														</a>
													</td>
												</tr>
												<tr>
													<td>
														<Trans ns="dataProtection">Mas info</Trans>:
													</td>
													<td>
														<Trans ns="dataProtection">
															Dispone de más información en nuestra
														</Trans>
														<Link to="/legal/pollitica-privacidad">
															<Trans ns="dataProtection">
																Política de Privacidad
															</Trans>
														</Link>
													</td>
												</tr>
											</tbody>
										</table>
									</div> */}
								</form>
							</div>
						) : (
							<div>
								<p>
									<Trans>
										Solicitud recibida. Pronto nos pondremos en contacto
										contigo.
									</Trans>
								</p>
							</div>
						)}
					</>
				</CSSTransition>
			</SwitchTransition>
		</div>
	);
}
